import styled from 'styled-components';

// helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//components
import { Container } from '@/components/Grid';

//assets
import { IconArrowsExpand } from '@loadsmart/icons';

export const ContainerWrapper = styled.div`
  position: relative;
  background-color: ${(props) =>
    props.theme === 'light'
      ? `rgb(${getToken('color-background-primary')})`
      : `rgb(${getToken('color-background-inverted')})`};
`;

export const MainContent = styled(Container)`
  display: grid;
  gap: ${getToken('spacing-3')};
  align-items: center;
  grid-template-columns: 120px 60px;
  justify-content: space-between;

  position: unset;

  ${screen.lg} {
    position: relative;
    grid-template-columns: 142px 1fr;
  }
  ${screen.xxlg} {
    grid-template-columns: 166px 1fr;
  }
`;

export const LogoWrapper = styled.div`
  text-decoration: none;
  display: inline-block;

  svg {
    path {
      fill: rgb(${getToken('color-brand')});
    }
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;

  padding: ${getToken('spacing-4')} ${getToken('spacing-1')}
    ${getToken('spacing-5')};
  background-color: rgb(${getToken('color-background-secondary-inverted')});
  display: none;

  &.open {
    display: grid;
  }

  ${screen.lg} {
    padding: 0;
    position: relative;
    display: grid;
    justify-content: flex-end;
    top: unset;
    left: unset;
    background: transparent;
  }
`;

export const NavContent = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(${(props) => props.theme.breakpoint.small} - 6rem);
  position: relative;
  margin: 0 auto;

  ${screen.md} {
    max-width: calc(${(props) => props.theme.breakpoint.medium} - 6rem);
  }

  ${screen.lg} {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
  }
`;

export const MobileMenuTrigger = styled.button`
  display: flex;
  align-items: flex-end;
  background: transparent;
  border: none;
  padding: 0;
  min-height: 60px;
  min-width: 60px;

  &.open {
    background-color: rgb(${getToken('color-background-secondary-inverted')});
  }

  ${screen.lg} {
    display: none;
    pointer-events: none;
  }
`;

export const MobileMenuLabel = styled.span`
  display: none;
`;

export const MobileMenuAnimator = styled.div`
  width: 36px;
  height: 36px;
  position: relative;
  margin: auto;
  transform: rotate(0deg);

  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: ${(props) =>
      props.theme !== 'light'
        ? `rgb(${getToken('color-background-primary')})`
        : `rgb(${getToken('color-background-inverted')})`};
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  &.open {
    span {
      background-color: rgb(${getToken('color-background-primary')});
    }
  }

  span:nth-child(1) {
    top: 4px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 14px;
  }

  span:nth-child(4) {
    top: 24px;
  }

  &.open {
    span:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
  }
`;

export const MenuItem = styled.div`
  position: relative;
  margin: ${getToken('spacing-2')} 0;

  ${screen.lg} {
    margin: 0;
  }
`;

export const MenuButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-regular')};
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: ${getToken('spacing-1')};
  color: rgb(${getToken('color-text-inverted')});

  ${screen.lg} {
    width: auto;
    justify-content: unset;
    color: ${(props) =>
      props.theme === 'light'
        ? `rgb(${getToken('color-text-primary')})`
        : `rgb(${getToken('color-text-inverted')})`};
    font-size: 16px;
    line-height: 24px;
    font-weight: ${getToken('font-weight-bold')};
    margin: 0 ${getToken('spacing-1')};
    padding: 17px 10px;

    &:hover {
      background-color: ${(props) =>
        props.theme === 'light'
          ? `rgb(${getToken('color-background-tertiary')})`
          : `rgb(${getToken('color-background-secondary-inverted')})`};
    }
  }

  ${screen.xxlg} {
    margin: 0 ${getToken('spacing-2')};
    padding: 29px 20px;
  }

  &:focus-visible {
    outline: none !important;
    border-radius: ${getToken('border-radius-s')};
    border: 1px solid rgb(${getToken('color-text-inverted')});

    ${screen.lg} {
      border-radius: none;
      border: 1px solid
        ${(props) =>
          props.theme === 'light'
            ? `rgb(${getToken('color-primary-60')})`
            : `rgb(${getToken('color-brand')})`};
    }
  }

  &.open {
    background-color: rgb(${getToken('color-background-secondary-inverted')});
    color: rgb(${getToken('color-text-inverted')});

    svg {
      fill: rgb(${getToken('color-text-inverted')});
    }
  }

  &.highlight {
    span {
      color: green;
      background: linear-gradient(
        45.01deg,
        rgb(${getToken('color-brand-accent-1')}) 23.29%,
        rgb(${getToken('color-brand-accent-2')}) 88.12%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    svg {
      path {
        fill: rgb(${getToken('color-brand-accent-2')});
      }
    }
  }
`;

export const ItemLabel = styled.span`
  width: max-content;
`;

export const Carret = styled(IconArrowsExpand)`
  margin: auto 20px auto 0;
  fill: rgb(${getToken('color-text-inverted')});

  ${screen.lg} {
    margin: auto 0 auto ${getToken('spacing-2')};
    fill: ${(props) =>
      props.theme === 'light'
        ? `rgb(${getToken('color-text-primary')})`
        : `rgb(${getToken('color-text-inverted')})`};
  }
`;

export const SubmenuList = styled.nav`
  padding: ${getToken('spacing-5')} ${getToken('spacing-8')} 0;
  background-color: rgb(${getToken('color-background-secondary-inverted')});
  position: relative;
  border-radius: 0 0 ${getToken('border-radius-s')}
    ${getToken('border-radius-s')};

  z-index: 10;
  width: 100%;

  display: none;
  flex-direction: column;

  &.open {
    display: flex;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    left: 6px;
    width: 2px;
    height: calc(100% - 20px);

    background: linear-gradient(
      45.01deg,
      rgb(${getToken('color-brand-accent-1')}) 23.29%,
      rgb(${getToken('color-brand-accent-2')}) 88.12%
    );
  }

  ${screen.lg} {
    width: 333px;
    box-shadow: ${getToken('elevation-2')};
    margin: 0 ${getToken('spacing-1')};
    padding: ${getToken('spacing-4')} ${getToken('spacing-8')};
    position: absolute;
    top: 100%;
    right: 0;

    &.small {
      width: 180px;
    }

    &::after {
      display: none;
    }
  }

  ${screen.xxlg} {
    margin: 0 ${getToken('spacing-2')};
  }
`;

export const SubmenuLink = styled.a`
  margin: ${getToken('spacing-2')} 0;
  padding: ${getToken('spacing-1')};

  ${screen.lg} {
    margin: ${getToken('spacing-5')} 0;

    &.bold {
      margin: ${getToken('spacing-4')} 0;
    }
  }

  &:hover {
    span {
      color: rgb(${getToken('color-text-inverted')});
    }
  }

  &:focus-visible {
    outline: none !important;

    border: 1px solid rgb(${getToken('color-text-inverted')});
    border-radius: ${getToken('border-radius-s')};
  }
`;

export const SubmenuLinkLabel = styled.span`
  display: flex;
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-regular')};
  font-size: 16px;
  line-height: 24px;
  color: rgb(${getToken('color-text-secondary-inverted')});

  &.bold {
    padding: ${getToken('spacing-1')} 0;
  }

  &.highlight {
    color: green;
    background: linear-gradient(
      45.01deg,
      rgb(${getToken('color-brand-accent-1')}) 23.29%,
      rgb(${getToken('color-brand-accent-2')}) 88.12%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  ${screen.lg} {
    font-weight: ${getToken('font-weight-bold')};
    color: rgb(${getToken('color-text-inverted')});
  }
`;

export const SubmenuLinkDesc = styled.span`
  color: rgb(${getToken('color-text-secondary-inverted')});
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  font-size: 14px;
  line-height: 21px;

  display: none;

  ${screen.lg} {
    display: flex;
  }
`;
