import styled from 'styled-components';
import { screen } from '@/helpers/screen';

export const ContainerStyle = styled.div`
  max-width: calc(100% - 42px);
  width: 100%;
  margin: 0 auto;
  position: relative;

  &.text-center {
    text-align: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.small}) {
    max-width: calc(${(props) => props.theme.breakpoint.small} - 6rem);
  }

  ${screen.md} {
    max-width: calc(${(props) => props.theme.breakpoint.medium} - 6rem);
  }

  ${screen.lg} {
    max-width: calc(${(props) => props.theme.breakpoint.big} - 4.4rem);
  }

  @media (min-width: ${(props) => props.theme.breakpoint.biggest}) {
    max-width: calc(1440px - 6rem);
  }
`;
