import styled from 'styled-components';
import { Container } from '@/components/Grid/Container';

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ color }) => (color ? color : '#eff9f9')};
`;

export const Divider = (props) => (
  <Container>
    <Line {...props} />
  </Container>
);
