import styled from 'styled-components';

import { screen } from '@/helpers/screen';
import { Container, Section } from 'components/Grid';

const reverse = ({ reverse }) =>
  reverse ? 'flex-direction: row-reverse;' : 'flex-direction: row;';
const mobileReverse = ({ mobileReverse }) =>
  mobileReverse ? 'flex-direction: column-reverse;' : 'flex-direction: column;';

const IgnoreContainerOnSmall = styled(Container)`
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  ${screen.md} {
    max-width: 674px;
  }
  ${screen.lg} {
    max-width: 980px;
  }
  ${screen.xxlg} {
    max-width: 1380px;
  }
`;

const SideBySide = styled.div`
  ${mobileReverse};
  display: flex;

  ${screen.md} {
    ${reverse} & > * {
      width: 50%;
      max-width: 50%;
    }
  }
`;

export const Panel = ({ children, ...other }) => (
  <IgnoreContainerOnSmall>
    <SideBySide {...other}>{children}</SideBySide>
  </IgnoreContainerOnSmall>
);

export const SectionPanel = ({
  reverse,
  mobileReverse,
  children,
  ...other
}) => (
  <Section {...other}>
    <Panel reverse={reverse} mobileReverse={mobileReverse}>
      {children}
    </Panel>
  </Section>
);
