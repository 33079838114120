import styled, { css } from 'styled-components';
import { screen } from '@/helpers/screen';

const SectionBase = styled.section`
  width: 100%;
`;

const SectionResponsiveMargin = css`
  ${screen.md} {
    margin: 60px 0;
  }

  ${screen.lg} {
    margin: 80px 0;
  }

  ${screen.xxlg} {
    margin: 100px 0;
  }
`;

export const SectionNoMarginOnMobile = styled(SectionBase)`
  ${SectionResponsiveMargin};
`;

export const Section = styled(SectionBase)`
  margin: 50px 0;
  ${SectionResponsiveMargin};
`;

export const InvertedSection = styled(SectionBase)`
  padding: 50px 0;

  ${screen.md} {
    padding: 60px 0;
  }

  ${screen.lg} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const StickyTopSectionOnMobile = styled(Section)`
  margin: 0 0 50px;
  ${SectionResponsiveMargin};
`;

export const SectionTitle = styled.h1`
  font-size: 24px;
  line-height: 30px;
  text-align: center;

  ${screen.md} {
    font-size: 28px;
    line-height: 45px;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }
`;
