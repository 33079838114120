import styled from 'styled-components';

// helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//components
import { Container } from '@/components/Grid';

export const ContainerWrapper = styled.footer`
  background-color: rgb(${getToken('color-background-inverted')});
  color: rgb(${getToken('color-text-secondary-inverted')});
  font-family: ${getToken('font-family-title')};
  font-size: 14px;
  padding: 80px 0;

  ${screen.md} {
    padding: 120px 0;
  }
`;

export const MainContent = styled(Container)``;

export const LogoWrapper = styled.div``;

export const LogoContent = styled.a`
  text-decoration: none;
  display: inline-block;

  svg {
    path {
      fill: rgb(${getToken('color-text-secondary-inverted')});
    }
  }
`;

export const NavWrapper = styled.div`
  padding: 52px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${screen.lg} {
    flex-direction: row;
  }
`;

export const NavSegments = styled.nav`
  display: grid;
  gap: 48px;

  ${screen.md} {
    grid-template-columns: repeat(2, auto);
  }
  ${screen.xxlg} {
    grid-template-columns: repeat(4, auto);
  }
`;

export const NavSegmentsGroup = styled.div`
  diplay: flex;
  flex-direction: column;
`;

export const SegmentLabel = styled.span`
  display: inline-flex;
  color: rgb(${getToken('color-text-inverted')});
  font-weight: 600;
  font-size: 20px;

  margin: 32px 0;
`;

export const SegmentSubItems = styled.div`
  display: flex;
  flex-direction: column;

  a {
    display: inline-block;
    font-size: 16px;
    margin: 16px 0;
    max-width: fit-content;
  }
`;

export const NavIcons = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 430px;
  margin: 64px auto 0;
  gap: 16px;

  ${screen.lg} {
    margin-top: 0;
    max-width: unset;
    min-width: 450px;
  }
`;

export const NavSocials = styled.nav`
  display: flex;
  height: fit-content;
  justify-content: space-between;
  gap: 8px;

  ${screen.md} {
    gap: 32px;
  }

  a {
    display: inline-block;
    margin: 16px 0;
  }
`;

export const NavApps = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 8px;

  a {
    display: inline-block;
    margin: 16px 0;
  }

  ${screen.md} {
    flex-direction: row;
    gap: 32px;

    a {
      margin: 8px 0;
    }
  }
`;

export const LegalWrapper = styled.nav`
  padding: 52px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  a {
    display: inline-block;
    margin: 8px 0;
    width: fit-content;
  }
`;

export const CopyRightWrapper = styled.div``;

export const Spacer = styled.hr`
  border: none;
  height: 1px;
  background: rgb(${getToken('color-text-secondary-inverted')});
  margin: 0;
`;
