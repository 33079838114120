import classNames from 'classnames';

//styles
import { ContainerStyle } from './Container.styles';

export default function Container({ children, variant, ...props }) {
  var containerClass = classNames({
    'text-center': variant === 'center' ? 'center' : false,
  });

  return (
    <ContainerStyle className={containerClass} {...props}>
      {children}
    </ContainerStyle>
  );
}

export { Container };
