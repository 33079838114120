export const defaultOptions = {
  portalId: '5041527',
  formId: '5ca67de8-0c15-4dec-8671-863f1c87cefc',
  sfdcCampaignId: '701f40000013BH4AAM',
  target: 'integration-hubspot',
};

export const carrierOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: '958785f2-d686-41c3-a970-e620e339b5bf',
  sfdcCampaignId: '7015G000001qQIcQAM',
};

export const carrierPageOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: 'f8c53fec-5426-4d12-a6e2-79375099884d',
  sfdcCampaignId: '7015G000001qQIcQAM',
};

export const enterpriseShippingOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: 'c47069a6-2c7b-4c2e-b28d-6126e58f1e2a',
  sfdcCampaignId: '7015G000000Tc0AQA',
};

export const dataInsightsOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: '01799528-ca38-4dab-9a23-ff9ed0eeab94',
  sfdcCampaignId: '7015G000000TdJhQAK',
};

export const tmsIntegrationOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: '34003116-8389-4ded-8b6b-cd8dcd4fcc47',
  sfdcCampaignId: '7015G000000TdsXQAS',
};

export const modeOptimizationOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: '6e402403-fb02-48c0-8e21-71b6e9fada5c',
  sfdcCampaignId: '7015G000000TcSsQAK',
};

export const smbShippingOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: '78d15ec2-d40a-44d5-a611-21bb03e57240',
  sfdcCampaignId: '7015G000000TdViQAK',
};

export const multimodalOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: '61f6ea13-b0ec-466f-b1b9-7bb515fa23b6',
  sfdcCampaignId: '7015G000001qSxCQAU',
};

export const managedTransOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: '61f6ea13-b0ec-466f-b1b9-7bb515fa23b6',
  sfdcCampaignId: '',
};

export const expeditedShippingOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: 'f35c14de-9acb-45cb-8234-21cce989e585',
  sfdcCampaignId: '',
};

export const shipperGuideOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: '8249220c-3449-405f-b7b8-96d458e08662',
  sfdcCampaignId: '',
};

export const warehouseOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: '2e969d1c-cda3-4d42-8f0e-b2cf5f7fd2c1',
  sfdcCampaignId: '7015G000000TdtGQAS',
};

export const subprocessorsOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: 'b3b90e4e-1377-4945-a38a-7de644fd50d4',
  sfdcCampaignId: '',
};

export const managedTransROICalcOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: '849efbae-0964-469f-a6a9-77f1acf61f4d',
  sfdcCampaignId: '',
  target: 'form-hubspot-embed-roi',
};

export const shipperGuideROICalcOptions = {
  region: 'na1',
  portalId: '5041527',
  formId: '5490266a-050d-4c08-b207-449cf2b97e15',
  sfdcCampaignId: '',
  target: 'form-hubspot-embed-roi',
};

export const setHubspotFormIntegration = (options) => {
  const script = document.createElement('script');

  script.src = 'https://js.hsforms.net/forms/v2.js';
  script.onload = () => {
    if (window.hbspt) {
      window.hbspt.forms.create(options);
    }
  };

  document.body.appendChild(script);
};

export const DisclaimerText = () => {
  return (
    <p>
      * By providing a telephone number and submitting the form you are
      consenting to be contacted by SMS text message. Message &amp; data rates
      may apply. Reply STOP to opt out of further messaging.
    </p>
  );
};
