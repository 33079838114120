import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';
import {
  FlexColumn,
  FlexColumnFullCentered,
  secondaryAxisCentered,
} from 'components/Grid/Flex';

const hideOnMedium = ({ hideOnMedium }) =>
  hideOnMedium ? 'display: none' : 'display: block';

export const MobileOnlyContainer = styled(Container)`
  ${screen.md} {
    max-width: 100%;
  }
`;

const BaseWrapper = styled(FlexColumn)`
  height: 100%;
  text-align: center;
  margin-top: 20px;

  ${screen.md} {
    margin: 0;
    padding-left: 30px;
    text-align: left;
  }

  ${screen.lg} {
    padding-left: 37px;
  }

  ${screen.xxlg} {
    padding-left: 57px;
  }
`;

export const Wrapper = (props) => (
  <MobileOnlyContainer>
    <BaseWrapper {...props} />
  </MobileOnlyContainer>
);

export const ReversedWrapper = styled(Wrapper)`
  ${screen.md} {
    padding-left: 0;
    padding-right: 30px;
  }

  ${screen.lg} {
    padding-right: 37px;
  }

  ${screen.xxlg} {
    padding-right: 57px;
  }
`;

export const CenteredWrapper = styled(Wrapper)`
  ${secondaryAxisCentered};
`;

export const ReversedCenteredWrapper = styled(ReversedWrapper)`
  ${secondaryAxisCentered};
`;

export const ResponsiveImage = styled.img`
  width: 100%;
  height: auto;
`;

const WideImageWrapper = styled(FlexColumnFullCentered)`
  height: 250px;
  overflow: hidden;

  ${screen.md} {
    ${hideOnMedium};
    height: auto;
    justify-content: flex-start;
  }
`;

export const TitleSection = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  margin: 0;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;

export const SubTitle = styled.h4`
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;

  ${screen.md} {
    font-size: 15px;
    line-height: 18px;
    text-align: left;

    br {
      display: none;
    }
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 23px;
  }

  ${screen.xxlg} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const Description = styled.h4`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    line-height: 26px;
    margin-top: 10px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 44px;
    margin-top: 8px;
  }
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  margin: 16px 0;
  text-align: center;

  ${screen.md} {
    margin: 28px 0;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
    margin: 36px 0;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
    margin: 42px 0;
  }
`;

export const Link = styled.a`
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-decoration-line: underline;
  cursor: pointer;
  color: inherit;

  &:hover {
    color: #6c8694;
  }

  ${screen.md} {
    font-size: 15px;
    line-height: 18px;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 23px;
  }

  ${screen.xxlg} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const VideoWrapper = styled.div`
  margin: 0 auto;
  height: 220px;
  width: 100%;
  position: relative;

  ${screen.md} {
    align-self: center;
    height: 191px;
  }

  ${screen.lg} {
    height: 278px;
  }

  ${screen.xxlg} {
    height: 390px;
  }
`;

export const WideImageOnMobile = ({ hideOnMedium, ...props }) => (
  <WideImageWrapper hideOnMedium={hideOnMedium}>
    <ResponsiveImage {...props} />
  </WideImageWrapper>
);
